@import url('https://code.cdn.mozilla.net/fonts/fira.css'); 
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'), url(../fonts/Manrope-VariableFont_wght.ttf) format(truetype);
  }
html,
body {
    margin: 0;
    height: 100%;
}

body {
    /* font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    font-family: Manrope;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-weight: 500;
}

footer {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
}

div#app {
    height: inherit;
}

.panel {
    margin-bottom: 10px;
}

.panel-body {
    overflow: none;
}

.wrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.wrapper:before,
.wrapper:after {
    content: " ";
    display: table;
}

.wrapper:after {
    clear: both;
}

.content-wrapper {
    overflow: auto;
    /*overflow: none;*/
    padding: 28px 0;
    /*position: absolute;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.content-wrapper {
    -webkit-transition: -webkit-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
    margin-left: 250px;
}

.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1500px) {
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12 {
        float: left;
    }
    .col-xl-12 {
        width: 100%;
    }
    .col-xl-11 {
        width: 91.66666667%;
    }
    .col-xl-10 {
        width: 83.33333333%;
    }
    .col-xl-9 {
        width: 75%;
    }
    .col-xl-8 {
        width: 66.66666667%;
    }
    .col-xl-7 {
        width: 58.33333333%;
    }
    .col-xl-6 {
        width: 50%;
    }
    .col-xl-5 {
        width: 41.66666667%;
    }
    .col-xl-4 {
        width: 33.33333333%;
    }
    .col-xl-3 {
        width: 25%;
    }
    .col-xl-2 {
        width: 16.66666667%;
    }
    .col-xl-1 {
        width: 8.33333333%;
    }
}

@media (max-width: 670px) {
    .col-xxs-0 {
        display: none !important;
    }
}

@media (max-width: 800px) {
    .content-wrapper {
        margin-left: 0;
        padding-top: 100px;
    }
    .sidebar-collapse .content-wrapper {
        margin-left: 0;
    }
    .sidebar-open .content-wrapper {
        -webkit-transform: translate(250px, 0);
        -ms-transform: translate(250px, 0);
        -o-transform: translate(250px, 0);
        transform: translate(250px, 0);
    }
}

.content-wrapper {
    height: inherit;
    padding-bottom: 50px;
    background-color: #F7F7F7;
}

.content-wrapper footer {
    position: absolute;
    bottom: 0;
}

.main-footer {
    background: #fff;
    padding: 15px 5px;
    margin: 0;
    color: #262b68;
    border-top: 1px solid #d2d6de;
}

.main-footer div {
    margin-right: 15px;
    color: #4B4B4B;
}

body.hold-transition .content-wrapper,
body.hold-transition .main-footer,
body.hold-transition .main-sidebar,
body.hold-transition .main-header .navbar,
body.hold-transition .main-header .logo {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

/* todo: use alt font for headers?
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Source Sans Pro', sans-serif;
}*/

a {
    color: #000;
}

a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
    color: #72afd2;
}

.page-header {
    margin: 10px 0 20px 0;
    font-size: 22px;
}

.main-header {
    position: relative;
    max-height: 100px;
    z-index: 1030;
}

.main-header .navbar {
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-bottom: 0;
    border: none;
    min-height: 50px;
    border-radius: 0;
}

.main-header .navbar-btn {
    height: 100%;
    vertical-align: middle;
    margin: auto;
}

.main-header .navbar-custom-menu,
.main-header .navbar-right {
    float: right;
}

@media (max-width: 991px) {
    .main-header .navbar-custom-menu a,
    .main-header .navbar-right a {
        color: inherit;
        background: transparent;
    }
}

@media (max-width: 800px) {
    .main-header .navbar-right {
        float: none;
    }
    .navbar-collapse .main-header .navbar-right {
        margin: 7.5px -15px;
    }
    .main-header .navbar-right>li {
        color: inherit;
        border: 0;
    }
}

.main-header .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px 15px;
    min-width: 50px;
    width: 50px;
    color: #fff;
    display: none;
}

.main-header .sidebar-toggle:hover {
    background-color: #cbd6e3;
}

.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
    background: transparent;
}

.main-header .logo {
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 250px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    overflow: hidden;
    padding: auto 0px;
}

.main-header .logo .logo-lg {
    display: block;
}

.main-header .logo .logo-mini {
    display: none;
}

.content-header {
    position: relative;
    padding: 15px 15px 0 15px;
}

.content-header>h1 {
    margin: 0;
    color: #262b68;
    font-size: 24px;
}

.navbar-toggle {
    color: #fff;
    border: 0;
    margin: 0;
    padding: 15px 15px;
}

.navbar-btn-container,
.navbar-btn-container > .navbar-btn {
    height: 50px !important;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .navbar-custom-menu .navbar-nav>li {
        float: left;
    }
    .navbar-custom-menu .navbar-nav {
        margin: 0;
        float: left;
    }
    .navbar-custom-menu .navbar-nav>li>span {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 20px;
    }
}

@media (max-width: 800px) {
    .main-header {
        position: relative;
    }
    .main-header .logo,
    .main-header .navbar {
        width: 100%;
        float: none;
    }
    .main-header .navbar {
        margin: 0;
    }
    .main-header .navbar-custom-menu {
        float: right;
    }
    .main-header .sidebar-toggle {
        display: inline-block;
    }
    .main-sidebar .sidebar-collapse {
        display: none;
    }
}

@media (max-width: 991px) {
    .navbar-collapse.pull-left {
        float: none !important;
    }
    .navbar-collapse.pull-left+.navbar-custom-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 40px;
    }
}

.main-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 50px;
    min-height: 100%;
    width: 250px;
    z-index: 810;
    -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

@media (max-width: 800px) {
    .main-sidebar {
        padding-top: 100px;
    }
}

@media (max-width: 800px) {
    .main-sidebar {
        -webkit-transform: translate(-250px, 0);
        -ms-transform: translate(-250px, 0);
        -o-transform: translate(-250px, 0);
        transform: translate(-250px, 0);
    }
}

@media (min-width: 800px) {
    .sidebar-collapse .main-sidebar {
        -webkit-transform: translate(-250px, 0);
        -ms-transform: translate(-250px, 0);
        -o-transform: translate(-250px, 0);
        transform: translate(-250px, 0);
    }
}

@media (max-width: 800px) {
    .sidebar-open .main-sidebar {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.sidebar {
    padding-bottom: 10px;
}

.user-panel {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow: hidden;
}

.user-panel:before,
.user-panel:after {
    content: " ";
    display: table;
}

.user-panel:after {
    clear: both;
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-menu>li {
    position: relative;
    margin: 0;
    padding: 0;
}

.sidebar-menu>li>a {
    padding: 12px 10px;
    display: block;
    font-weight: bold;  
}

.sidebar-menu li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px;
}

.sidebar-menu li.active>.treeview-menu {
    display: block;
}

.sidebar-menu .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-menu .treeview-menu .treeview-menu {
    padding-left: 20px;
}

.sidebar-menu .treeview-menu>li {
    margin: 0;
    background-color: #fff;
}

.sidebar-menu .treeview-menu>li>a {
    padding: 5px 5px 5px 15px;
    width: auto;
    display: block;
    font-size: 14px;
    background-color: #fff;
}

@media (min-width: 800px) {
    .sidebar-mini.sidebar-collapse .content-wrapper {
        margin-left: 50px !important;
        z-index: 840;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 50px !important;
        z-index: 850;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li {
        position: relative;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>a {
        margin-right: 0;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span {
        border-top-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:not(.treeview)>a>span {
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:not(.treeview)>span {
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu {
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right-container),
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
        display: block !important;
        position: absolute;
        width: 250px;
        overflow: hidden;
        left: 50px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span {
        top: 0;
        margin-left: -3px;
        padding: 12px 5px;
        background-color: inherit;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>.pull-right-container {
        float: right;
        width: auto !important;
        left: 200px !important;
        top: 10px !important;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
        top: 44px;
        margin-left: 0;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span,
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu,
    .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>.pull-right,
    .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
        display: none !important;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
    }
}

.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu>li.header {
    white-space: nowrap;
    overflow: hidden;
}

.sidebar-menu:hover {
    overflow: visible;
}

.sidebar-menu>li.header {
    overflow: hidden;
    text-overflow: clip;
}

.sidebar-menu li>a {
    position: relative;
}

.sidebar-menu li>a>.pull-right-container {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
}

@keyframes flipInX {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transition-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        transform: perspective(400px);
    }
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-transition-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        -webkit-transform: perspective(400px);
    }
}

.navbar-custom-menu>.navbar-nav>li {
    position: relative;
}

@media (max-width: 991px) {
    .navbar-custom-menu>.navbar-nav {
        float: right;
    }
    .navbar-custom-menu>.navbar-nav>li {
        position: static;
    }
}

.btn {
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
}

.btn:active,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn.btn-flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-width: 1px;
}

.btn.btn-default.btn-flat {
    background-color: #04636D;
    color: #fff;
    border-color: #ddd;
}

.btn:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn:focus {
    outline: none;
}

.btn-default {
    background-color: #04636D;
    color: #fff;
    border-color: #ddd;
}

.export-button {
    background-color: #fff;
    color: #04636D;
    border-color: #ddd;
}

.btn-default:hover {
    background-color: #e7e7e7;
}

.btn-default:focus {
    outline: 0;
    background-color: unset;
}

div.rt-td { align-self: center; }

div.rt-tr { align-items: center; }

.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn[class*='bg-']:hover {
    -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}

.btn-app {
    border-radius: 3px;
    position: relative;
    padding: 15px 5px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    height: 60px;
    text-align: center;
    color: #666;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    font-size: 12px;
}

.btn-app:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa;
}

.btn-app:active,
.btn-app:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.nav>li>a:hover,
.nav>li>a:active,
.nav>li>a:focus {
    color: #444;
    background: #f7f7f7;
}

.login-logo {
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 300;
}

.login-logo a {
    color: #444;
}

.login-page {
    background: #d2d6de;
}

.error-page {
    width: 600px;
    margin: 20px auto 0 auto;
}

@media (max-width: 991px) {
    .error-page {
        width: 100%;
    }
}

.error-page>.error-content {
    margin-left: 190px;
    display: block;
}

@media (max-width: 991px) {
    .error-page>.error-content {
        margin-left: 0;
    }
}

.error-page>.error-content>h3 {
    font-weight: 300;
    font-size: 25px;
}

@media (max-width: 991px) {
    .error-page>.error-content>h3 {
        text-align: center;
    }
}

.post {
    border-bottom: 1px solid #d2d6de;
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #666;
}

.post:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.margin {
    margin: 10px;
}

[class^="bg-"].disabled {
    opacity: 0.65;
    filter: alpha(opacity=65);
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.flat {
    border-radius: 0 !important;
}

.img-sm,
.img-md,
.img-lg {
    float: left;
}

.img-sm {
    width: 30px !important;
    height: 30px !important;
}

.img-md {
    width: 60px;
    height: 60px;
}

.img-lg {
    width: 100px;
    height: 100px;
}

@media print {
    .no-print,
    .main-sidebar,
    .main-header,
    .content-header {
        display: none !important;
    }
    .main-footer {
        margin-left: 0 !important;
        min-height: 0 !important;
        -webkit-transform: translate(0, 0) !important;
        -ms-transform: translate(0, 0) !important;
        -o-transform: translate(0, 0) !important;
        transform: translate(0, 0) !important;
    }
}

.skin-green .main-header .navbar {
    background-color: #fff;
}

.skin-green .main-header .navbar .nav>li>a,
.skin-green .main-header .navbar .nav>li>span {
    color: #4B4B4B;
    background-color: inherit;
}

.skin-green .main-header .navbar .nav>li>a:hover,
.skin-green .main-header .navbar .nav>.active>a {
    background: #cbd6e3;
    color: #f6f6f6;
}

.skin-green .main-header .logo {
    color: #ffffff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0 solid transparent;
}

.skin-green .main-header li.user-header {
    background-color: #cbd6e3;
}

.skin-green .content-header {
    background: transparent;
}

.skin-green .wrapper,
.skin-green .main-sidebar {
    background-color: #fff;
}

.skin-green .sidebar-menu>li.header {
    color: #4b646f;
    background: #1a2226;
}

.skin-green .sidebar-menu>li>a {
    border-left: 3px solid transparent;
}

.skin-green .sidebar-menu>li:hover>a,
.skin-green .sidebar-menu>li.active>a {
    color: #000;
    background: #fff;
    font-weight: 500;
}

.skin-green .sidebar-menu>li>.treeview-menu {
    margin: 0;
    background: #ecf0f5;
}

.skin-green .sidebar a {
    color: #262b68;
}

.skin-green .sidebar a:hover {
    text-decoration: none;
}

.skin-green .treeview-menu>li>a {
    color: #000;
    border: none;
    border-right-width: 3px;
    border-left-width: 3px;
}

.skin-green .treeview-menu>li>a.active,
.skin-green .treeview-menu>li>a:hover,
.skin-green .treeview-menu>li>a:active {
    color: #04636D;
    font-weight: bolder;
    background-color: #F2FBF9;
}

.toolbar-section-title {
    color: #4B4B4B;
    font-weight: 500;
}
.sort-field {
    color: #000;
    font-weight: 500;
}

.page-title {
    margin-left: 25px;
}

.page-title>h1 {
    margin: 0;
    font-size: 24px;
}

.panel-content {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 90px;
    padding: 15px;
}

ul.link li span {
    float: left;
    width: 350px;
}

.ts-link-btn,
.navbar-btn>a {
    border-color: #262b68;
    margin-right: 10px;
    color: #262b68;
    background-color: transparent;
}

a.ts-link-btn:hover,
a.ts-link-btn:visited,
a.ts-link-btn:active,
a.ts-link-btn:active:focus,
a.ts-link-btn:active:hover,
a.ts-link-btn:focus,
.navbar-btn>a:hover,
.navbar-btn>a:visited,
.navbar-btn>a:active,
.navbar-btn>a:active:focus,
.navbar-btn>a:active:hover,
.navbar-btn>a:focus {
    border-color: #262b68;
    outline-width: 0;
    color: #262b68;
    background-color: transparent;
}

.user-name {
    height: 100%;
    display: inline-flex;
    vertical-align: middle;
    margin: auto;
    padding: 15px;
    font-family: Manrope;
    color: #4B4B4B;
}

.img-logo-margin-fix {
    margin-top: -5px;
}

span.pull-right {
    margin-top: -7px;
}

[draggable="true"] {
    cursor: move;
}

.btn.btn-default {
    margin-right: 5px;
}

.btn.btn-tessellate {
    background-color: #F0565B;
    color: #fff;
    border-color: #F0565B;
    margin-right: 5px;
}

.btn.btn-tessellate.active,
.btn.btn-tessellate:active,
.btn.btn-tessellate:hover {
    color: #fff;
    filter: saturate(1.5);
}

.btn.btn-tessellate-blue {
    background-color: #262b68;
    color: #fff;
    border-color: #262b68;
}

.btn.btn-tessellate-blue.active,
.btn.btn-tessellate-blue:active,
.btn.btn-tessellate-blue:hover {
    color: #fff;
    filter: saturate(1.5);
}

p span {
    display: block;
}

aside {
    height: 100%;
}

.sidebar-collapse {
    width: 100%;
}

.sidebar-collapse>span {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #262b68;
    text-align: center;
    width: 50px;
    padding-bottom: 20px;
}

svg {
    color: #262b68;
}

.hidden {
    display: none;
}

h3 {
    margin-top: 10px;
}

.full-height {
    height: 100%;
}

.loader {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

i.text-danger {
    color: #ab001d;
}

i.text-info {
    color: #0073ab;
}

i.text-success {
    color: #262b68;
}

li.treeview>a>.svg-inline--fa {
    width: 30px;
}

/* Unauthorized/Login Pages  */
.login-container,
.unauthorized-container {
    padding: 0;
    margin: 0;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    color: #00221c;
}

/* Not Found (404) Page */

.not-found {
    background: #fff;
    color: #262b68;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    overflow: hidden;
}

.not-found .c {
    text-align: center;
    display: block;
    position: relative;
    width: 80%;
    margin: 100px auto;
}

.not-found ._404 {
    font-size: 220px;
    position: relative;
    display: inline-block;
    z-index: 2000;
    height: 250px;
    letter-spacing: 15px;
}

.not-found ._1 {
    text-align: center;
    display: block;
    position: relative;
    letter-spacing: 12px;
    font-size: 4em;
    line-height: 80%;
}

.not-found ._2 {
    text-align: center;
    display: block;
    position: relative;
    font-size: 20px;
}

.not-found .btn {
    background-color: #262b68;
    position: relative;
    display: inline-block;
    width: 358px;
    padding: 5px;
    z-index: 2000;
    font-size: 25px;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    margin-right: 10px
}

.not-found .right {
    float: right;
    width: 60%;
}

.btn-right {
    float: right;
}

.not-found hr {
    padding: 0;
    border: none;
    border-top: 5px solid #262b68;
    color: #262b68;
    text-align: center;
    margin: 0px auto;
    width: 420px;
    height: 10px;
    z-index: -10;
}

.not-found .cloud {
    width: 350px;
    height: 120px;
    background: #262b68;
    background: linear-gradient(top, #262b68 100%);
    background: -webkit-linear-gradient(top, #262b68 100%);
    background: -moz-linear-gradient(top, #262b68 100%);
    background: -ms-linear-gradient(top, #262b68 100%);
    background: -o-linear-gradient(top, #262b68 100%);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    position: absolute;
    margin: 120px auto 20px;
    z-index: 800;
    transition: ease 1s;
}

.not-found .cloud:after,
.not-found .cloud:before {
    content: '';
    position: absolute;
    background: #262b68;
    z-index: -1
}

.not-found .cloud:after {
    width: 100px;
    height: 100px;
    top: -50px;
    left: 50px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
}

.not-found .cloud:before {
    width: 180px;
    height: 180px;
    top: -90px;
    right: 50px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
}

.not-found .x1 {
    top: -50px;
    left: 100px;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0.9;
    -webkit-animation: moveclouds 15s linear infinite;
    -moz-animation: moveclouds 15s linear infinite;
    -o-animation: moveclouds 15s linear infinite;
    animation: moveclouds 15s linear infinite;
}

.not-found .x1_5 {
    top: -80px;
    left: 250px;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-animation: moveclouds 17s linear infinite;
    -moz-animation: moveclouds 17s linear infinite;
    -o-animation: moveclouds 17s linear infinite;
    animation: moveclouds 17s linear infinite;
}

.not-found .x2 {
    left: 250px;
    top: 30px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.6;
    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
    animation: moveclouds 25s linear infinite;
}

.not-found .x3 {
    left: 250px;
    bottom: -70px;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.8;
    -webkit-animation: moveclouds 25s linear infinite;
    -moz-animation: moveclouds 25s linear infinite;
    -o-animation: moveclouds 25s linear infinite;
    animation: moveclouds 25s linear infinite;
}

.not-found .x4 {
    left: 470px;
    bottom: 20px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.75;
    -webkit-animation: moveclouds 18s linear infinite;
    -moz-animation: moveclouds 18s linear infinite;
    -o-animation: moveclouds 18s linear infinite;
    animation: moveclouds 18s linear infinite;
}

.not-found .x5 {
    left: 200px;
    top: 300px;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.8;
    -webkit-animation: moveclouds 20s linear infinite;
    -moz-animation: moveclouds 20s linear infinite;
    -o-animation: moveclouds 20s linear infinite;
    animation: moveclouds 20s linear infinite;
}

@-webkit-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }
    100% {
        margin-left: -1000px;
    }
}

@-moz-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }
    100% {
        margin-left: -1000px;
    }
}

@-o-keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }
    100% {
        margin-left: -1000px;
    }
}

@keyframes moveclouds {
    0% {
        margin-left: 1000px;
    }
    100% {
        margin-left: -1000px;
    }
}

.no-overflow {
    overflow: unset !important;
}

.ts-select {
    text-align-last: center;
}

.ts-multiselect,
.ts-multiselect .multiselect-native-select .btn-group {
    width: 100%;
}

.ts-multiselect .multiselect-native-select {
    display: inline-block;
    position: static;
    width: 100%;
}

.ts-multiselect .btn,
.ts-multiselect .multiselect-container.dropdown-menu {
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 15vw;
    width: 100%;
    max-height: 75vh;
}

.ts-multiselect .multiselect-container.dropdown-menu:hover {
    overflow-x: visible;
}

.ts-data-source .react-bootstrap-table tr td {
    vertical-align: middle;
    margin: auto;
}

.rt-resizable-header-content {
    font-weight: bold;
}

select.ts-select {
    width: 100%;
}

.selectBox {
    border-radius: 4px;
    border: 1px solid #AAAAAA;
}

.ts-btn-row {
    margin-top: 25px;
}

.ts-btn-row .col-md-12 {
    text-align: right;
}

.ts-btn-row .col-md-12 span {
    margin-right: 10px;
    margin-left: 10px;
}

.ts-toastr-error-position {
    top: 50px;
    right: 0;
    width: 100%;
}

#toast-container.ts-toastr-error-position>div {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.ts-link-btn {
    width: 38px;
}

.modal {
    display: unset;
}

.modal .modal-dialog {
    z-index: 1050;
}

.modal-size
{
    width: 900px !important;
    height:650px !important;
}

.modal .overlay {
    opacity: 0.6;
    background-color: #333;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1049;
}

.ts-react-table .rt-th {
    white-space: pre-line !important;
    word-wrap: break-word;
    vertical-align: center;
    margin: auto;
    font-weight: bold;
}

.ts-react-table .rt-td {
    vertical-align: center;
    margin: auto;
}

.padding-left {
    padding-left: 30px;
}

.removeButtonBorder {
    background-color: transparent !important;
    border-color: transparent !important;
}

.ReactTable .rt-thead [role="columnheader"] {
    outline: 0;
}

.ReactTable
{
   height: calc(100vh*0.6);
}

.ReactTable .rt-thead {
    overflow-y: initial;
 }

.center {
    text-align: center;
}

.visibilityHidden
{
    visibility: hidden;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

input[type="checkbox"] {
    margin: 4px;
    line-height: normal;
}

.lob {
    position: relative;
    vertical-align: middle;
    z-index: 1030;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: #4B4B4B;
    font-size: 24px;
    padding-left: 15px;
    font-style: normal;
}

.panel-default>.panel-heading {
    color: #000;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.h4, h4 {
    color: #000;
}