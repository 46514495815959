.date-picker-container {
    z-index: 800;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.date-picker-container .date-picker-icon {
    margin-left: -20px;
    pointer-events: none;
}

.inline-flex-container {
    display: inline-flex;
}

.ts-multi-select {
    text-align: left;
}

.ts-multi-select b.caret {
    float: right;
    margin-top: 8px;
}

.ts-multi-select .multiselect-container.dropdown-menu {
    position: relative;
    z-index: 9999999 !important;
}

.control-label {
    line-height: 34px;
    vertical-align: middle;
}

.form-inline>.form-group,
.form-inline>button.btn {
    margin: auto 10px;
}

.form-inline>.form-group>label {
    margin-right: 5px;
}

.form-inline>.form-group>select,
.form-inline>.form-group>.inline-flex-container,
.form-inline>.form-group>.ts-searchable-dropdown,
.form-inline>.form-group>input {
    margin: 10px 5px;
}

.form-inline>.form-group>label.control-label.radio-label {
    margin-right: 15px;
}

.form-inline>.form-group>div.ts-multiselect {
    width: auto;
    display: inline-block;
    margin: 10px 5px;
}

.export-spacing {
    margin: 10px;
}

.ts-searchable-dropdown {
    display: inline-block;
    min-width: 300px;
}

.header-column {
    float: left;
    width: 300px;
}

.header-label {
    width: 100px;
}

.header-details {
    padding: 10px;
    padding-bottom: 0px;
}
.header-details>.row>div>label {
    padding-right: 5px;
}

.header-details>.row>div>span {
    padding-right: 10px;
}

div.tab-group-7 {
    width: 100%;
}

div.tab-group-7>button {
    width: 14.28%;
    min-width: 100px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* try header-details */
.member-details {
    padding: 10px;
    padding-bottom: 0;
}

.member-details>.row>.col-sm-12 {
    height: 34px;
}

.member-details>.row>.col-sm-12>label {
    padding-right: 5px;
}

.member-details>.row>.col-sm-12>span {
    padding-right: 10px;
}

.member-details button {
    margin-top: -5px;
}

.rt-num,
.rt-check {
    display: flex;
    justify-content: center;
}

.underline {
    text-decoration:underline;
}

.react-autosuggest__container {
    position: relative;
    display: inline-block;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 500px;
    overflow: auto;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__input {
    min-width: 300px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.react-autosuggest__input:hover {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.modalTable {
    width: 100%;
}

.modalTable thead {
    display: block;
    background-color: rgb(180, 180, 180);
} 


.modalTable tbody {
    display: block;
} 

.modalHeader {
    font-weight: bold;
    
}

.modalGrid {
    border: 1px solid black;
    border-collapse: collapse;
}

.modalConditionTable {
    background-color: rgb(180, 180, 180);
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border: 1px solid black;
}
.modalConditionTable td, .modalConditionTable th {
    border-left: 1px solid black;
    padding-left: 1px;
}


.flexWrapper {
    display: flex;
}

.flexLeft {
    flex: 0 0 50%;
}

.flexRight {
    flex: 1
}

.export-modal {
    padding: 15px 25px;
}

.export-modal hr {
    margin-bottom: 0;
}

div.btn-group.tab-group-7 > button.active.disabled,
div.btn-group.tab-group-7 > button.active.disabled:active,
div.btn-group.tab-group-7 > button.active.disabled:active:focus,
div.btn-group.tab-group-7 > button.active.disabled:focus {
    background-color: #262b68;
    border-color: #262b68;
    opacity: 1;
    outline: 0;
}

div.-pagination > div.-previous > button:hover,
div.-pagination > div.-next > button:hover {
	background-color: #ef5658 !important;
	color: #fff !important;
}

.paddingRight {
    padding-right: 20px;
}

div.col-sm-12.select{
    margin: auto 5px;
}
div.col-sm-12.select>label.control-label,
div.col-sm-12.select>.ts-searchable-dropdown {
    margin: 10px 5px;
}
div.row > div.col-sm-12.col-md-6.col-lg-4{
    padding-left: 30px;
    margin-bottom: 10px;
}
div.form-row > div.col-sm-12 {
    margin-top: 20px;
}
div>span.clientName {
    font-weight: bold;
    margin: 5px;
    font-size: 16px;
    text-align: center;
}

div.bg-success {
    background-color: #27a844;
}

.bg-primary {
    background-color: #007aff;
}

.bg-secondary {
    background-color: #6c757e;

}

.bg-light {
    background-color: #f8f9fb;
}

.bg-info {
    background-color: #17a2b7;
}

.bg-danger {
    background-color: #dc3546;
}

.text-white {
    color: #ffffff;
}

.text-dark {
    color: black;
}

.createJob-spacing {
    margin-top: -10px;
    margin-bottom: 5px;
    margin-right: 10px;
}
.btn-success {
    background-color: #04636d;
    color: #FFFFFF;
    border-color: #ddd;
}

.btn-success:hover {
    background-color: #e7e7e7;
    color: #000;
}

.btn-default:focus {
    outline: 0;
    background-color: unset;
}
.btn.btn-success>.fa-plus-circle {
    margin-right: 5px;
    color: #04636d;
}
div.row.jobHeader {
    padding-bottom: 15px;
}

span.error {
    color: #dc3546;
}

.table-row-cursor {
    cursor: pointer;
}

.upload-button {
    background-color: #04636D;
    color: #fff;
    border-color: #ddd;
}