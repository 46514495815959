/* todo: revisit / remove these commented-out styles (commented-out temporarily to avoid styling conflicts)
.modal-dialog {
    left: 50%;
    position: absolute;
    top: 20%;
    transform: translate(-50%, -20%) !important;
}

.btn {
    font-weight: 600;
}

th {
    font-weight: 600;
}

li.active-nav-item > a {
    color: #333 !important;
    font-weight: bold;
}

.form-control:focus {
    /*outline: none;
    border-color: #ccc;
    box-shadow: inherit;
}
*/

.requiredField:before {
    content: "* ";
    color: red;
    font-size: 1em;
}

/* .DnnModule (parent container) has a z-index of 902. Setting this ensures
that rc-slider handle tooltips render appropriately (i.e. on top of the container). */
.rc-slider-tooltip {
    z-index: 902;
}

.select-placeholder {
    color: #808080;
    padding: 6px 7px;
}

.select-placeholder:active, .select-placeholder:focus {
    color: #555;
}

.spinning {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(359deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(359deg);
    }
}