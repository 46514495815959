section.panel-content.panel-admin-login {
	overflow: hidden;
}

.panel-admin-login > .panel.panel-default {
	height: 100%;
}

.panel-admin-login > .panel.panel-default > .panel-body {
	height: 100%;
}

.admin-login {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.admin-login .btn.btn-default.btn-tessellate {
	margin-left: 10px;
}

div.col-sm-12.col-md-6.col-lg-4>div.form-group,
div.form-row>div.form-group,
.col-sm-12>.row>h3{
	margin-left: 10px;
}

.btn.btn-info.inline-block.btn-sm {
	margin-left: 30px;
}

.btn-group-vertical .btn {
    margin-bottom:5px;
}

.nav.nav-pills.nav-stacked {
	width:100%;
}
div>pre {
	resize:vertical;
	height: 500px;
	margin-bottom: 250px;
}

div.form-row>.col-sm-12.col-md-4 {
	margin-bottom: 18px;
}

div.col-sm-12.col-md-4>div.row>.col-md-8 {
	font-size: 13px;
	margin-bottom: 15px;
}

div.col-sm-12.col-md-4>.row 
{
	text-align: left;
}


.btn.toggle.adv-toggle.btn-sm.off.btn-default,
.btn.toggle-off.btn-sm.btn-default {
	margin-right: 0px;
}

.btn.btn-link.inline-block.btn-sm {
	width: 100%;
	text-align: center;
}

.show-pointer
{
  cursor: pointer !important;
}

div>h5 {
	font-size: 16px;
	margin-left: 10px;
	margin-bottom: 15px;
}

div>label {
	display: inline;
	margin-right: 25px;
	margin-left: 10px;
	font-size: 12px;
	font-weight: normal;
}

.jdLabel {
	font-weight: normal;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 13px;
	display: inline;
}
.jdRes {
	font-weight: normal;
	font-size: 13px;
	display: inline;
}

.jdCol {
	width: 33%;
}